import React from "react";
import Header from "./header/header";
import Footer from "./footer/footer";
export default props => {
   return (
      <>
         <Header {...props} />
         {props.children}
         <Footer />
      </>
   );
};