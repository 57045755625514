import React from "react";
import rehypeReact from "rehype-react";
import { FilterUndefinedChildren, GetComponentUsed } from "./mdUtils";

export const MdPageRenderer = props => {
   const { frontmatter, htmlAst, matixComponent, matixSections, baseContextComponents } = props;
   const BaseContext = baseContextComponents[frontmatter.context];

   const isSection = child => {
      if (matixSections[child.tagName]) {
         return true;
      }
      if (child.children) {
         const eleFound = child.children.find(element => {
            return matixSections[element.tagName];
         });
         return eleFound !== undefined;
      }
      return false;
   };

   const isSameGroup = (child, index) => {
      if (!index) {
         return false;
      }
      return (
         (isSection(child) && isSection(htmlAst.children[index - 1])) ||
         (!isSection(child) && !isSection(htmlAst.children[index - 1]))
      );
   };

   const manageGroups = (child, group) => {
      child.children = FilterUndefinedChildren(child);
      setFrontMatterToMatixSectionAndComponent(child);
      if (group) {
         group.children.push(child);
      } else {
         groups.push(child);
      }
   };

   const setFrontMatterToMatixSectionAndComponent = group => {
      if (group && group.children) {
         group.children.forEach(child => {
            if (child && typeof child !== "string" && child.children) {
               setFrontMatterToMatixSectionAndComponent(child);
            }
            if (matixSections[child.tagName]) {
               child.properties.frontmatter = JSON.stringify(frontmatter);
            } else if (matixComponent[child.tagName]) {
               child.properties.frontmatter = JSON.stringify(frontmatter);
            }
         });
      }
      return group;
   };

   let groups = [];
   htmlAst.children.forEach((child, index) => {
      if (isSameGroup(child, index)) {
         manageGroups(child, groups[groups.length - 1]);
      } else {
         const newGroupChild = {
            type: "element",
            tagName: "div",
            sec: isSection(child),
            children: [child]
         };
         manageGroups(newGroupChild);
      }
   });

   //load components to use :
   const componentsUsed = GetComponentUsed({ ...props, groups });
   const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: { ...componentsUsed, ...BaseContext }
   }).Compiler;

   return (
      <>
         {groups.map((child, index) => {
            if (child.sec) {
               return <section key={index}>{renderAst(child)}</section>;
            } else {
               return (
                  <BaseContext key={index} {...props.location}>
                     {renderAst(child)}
                  </BaseContext>
               );
            }
         })}
      </>
   );
};
