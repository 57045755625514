import React from "react";
import style from "./baseContext.module.scss";
import c from "classnames";

export default props => {
   return (
      <section>
         <div className={c("mx-auto", style.baseComponent)}>{props.children}</div>
      </section>
   );
};
