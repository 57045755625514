import React, { useState, useEffect } from "react";
import style from "./siteEvalContext.module.scss";
import c from "classnames";

export default props => {
   let evaluationProps = {};

   const [loaded, setLoaded] = useState(false);

   useEffect(() => {
      if (!loaded) {
         if (
            props.location &&
            props.location.search &&
            props.location.search.indexOf("?eval=") > -1
         ) {
            evaluationProps.siteToEval = props.location.search.replace("?eval=", "");
         } else {
            evaluationProps.siteToEval = null;
         }
         setLoaded(true);
      }
   }, [loaded, props.location, evaluationProps.siteToEval]);

   return (
      <section>
         <div className={c("container", style.containerContext)}>
            <div className={c("mx-auto text-center", style.siteEvalContext)}>{props.children}</div>
         </div>
      </section>
   );
};
