import React from "react";
import c from "classnames";
import { isMobile } from "../utils";
import { MdStringRenderer } from "@matix/markdown";

export default props => {
   const childprops = props.children.props;

   const headLine = childprops.children[0].props.children[0];
   let employeeChildrens = [...childprops.children];
   employeeChildrens.shift();

   const ContactForm = employeeChildrens[employeeChildrens.length - 1];
   employeeChildrens.pop();

   return (
      <section>
         <div className={c("pt-5 pb-2", isMobile() ? "bg-dark" : "bg-light")}>
            {/* <div className="container pb-5"> */}
            <div className="row text-center py-5">
               <div className={c("col mt-2", isMobile() ? "text-white" : "")}>
                  <MdStringRenderer source={headLine} />
               </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center">{employeeChildrens}</div>
            <div className="row mb-0 mx-2 mt-2 text-center">
               <div className={"col bg-light"}>{ContactForm}</div>
            </div>
         </div>
      </section>
   );
};
