import loadable from "@loadable/component";
import BaseContext from "../components/pagesContext/baseContext";
import EvalContext from "../components/pagesContext/siteEvalContext";
import ReportContext from "../components/pagesContext/siteReportContext";
import ContactContext from "../components/pagesContext/contactContext";
import HeroSmallImg from "../components/hero/heroSmallImg";
import HeroLight from "../components/hero/heroLight";
import HeroBack from "../components/hero/heroBackground";
// import Realisation from "../components/realisation/realisation";

// import matixImage from "../components/image";
// import matixSteps from "../components/stepFeature/stepFeature";
// import matixText from "../components/mdText";
// import matixCards from "../components/featuresCard/cardsContainer";
// import matixCard from "../components/featuresCard/featureCard";
// import matixFaq from "../components/faq";
// import matixHeadline from "../components/headline";
// import matixCardImageSideText from "../components/featuresCard/cardImageSideText";
// import matixSiteEval from "../components/siteEval/siteEvaluation";
// import matixRealisation from "../components/realisation/realisation";
// import matixLoginForm from "../components/forms/loginClient";
// import matixEmployeeCard from "../components/contact/contactCard";
// import matixContactForm from "../components/forms/contactForm";
// import matixSiteReport from "../components/reportEval/reportEval";
// import matixReportPerfo from "../components/reportEval/reportCard/perfo";

export const lazyComponent = {
   "matix-image": loadable(() => import("../components/image")),
   "matix-steps": loadable(() => import("../components/stepFeature/stepFeature")),
   "matix-text": loadable(() => import("../components/mdText")),
   "matix-cards": loadable(() => import("../components/featuresCard/cardsContainer")),
   "matix-card": loadable(() => import("../components/featuresCard/featureCard")),
   "matix-faq": loadable(() => import("../components/faq")),
   "matix-headline": loadable(() => import("../components/headline")),
   "matix-card-image-side-text": loadable(() =>
      import("../components/featuresCard/cardImageSideText")
   ),
   "matix-site-eval": loadable(() => import("../components/siteEval/siteEvaluation")),
   "matix-realisation": loadable(() => import("../components/realisation/realisation")),
   "matix-login-form": loadable(() => import("../components/forms/loginClient")),
   "matix-employee-card": loadable(() => import("../components/contact/contactCard")),
   "matix-contact-form": loadable(() => import("../components/forms/contactForm")),
   "matix-site-report": loadable(() => import("../components/reportEval/reportEval"))
   // "matix-report-perfo": loadable(() => import("../components/reportEval/reportCard/perfo"))
};

export const matixComponent = {};
//    "matix-image": matixImage,
//    "matix-steps": matixSteps,
//    "matix-text": matixText,
//    "matix-cards": matixCards,
//    "matix-card": matixCard,
//    "matix-faq": matixFaq,
//    "matix-headline": matixHeadline,
//    "matix-card-image-side-text": matixCardImageSideText,
//    "matix-site-eval": matixSiteEval,
//    "matix-realisation": matixRealisation,
//    "matix-login-form": matixLoginForm,
//    "matix-employee-card": matixEmployeeCard,
//    "matix-contact-form": matixContactForm,
//    "matix-site-report": matixSiteReport
// };

export const matixSections = {
   "matix-hero-small-img": HeroSmallImg,
   "matix-hero-light": HeroLight,
   "matix-hero-back": HeroBack
};

export const baseContextComponents = {
   "matix-base-context": BaseContext,
   "matix-eval-context": EvalContext,
   "matix-report-context": ReportContext,
   "matix-contact-context": ContactContext
};
