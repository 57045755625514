import React from "react";
import ReactMarkdown from "react-markdown/with-html";

export const MdStringRenderer = props => {
   const renderers = {
      text: ({ value }) => {
         const comps = value.split("$c");
         if (comps.length === 2 || comps.length === 3) {
            const endTagPos = comps[1].indexOf(" ");
            const colorTag = comps[1].substr(0, endTagPos);
            const comp1txt = comps[1].substr(endTagPos, comps[1].length - endTagPos);
            const txtStyle = `color: ${colorTag};`;
            return (
               <span>
                  {comps[0]}
                  <span style={txtStyle}>{comp1txt}</span>
                  {comps.length === 3 ? comps[2] : null}
               </span>
            );
         }
         return value;
      }
   };

   return <ReactMarkdown renderers={renderers} children={props.source} allowDangerousHtml={true} />;
};
