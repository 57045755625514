import React from "react";
import { SEO } from "@matix/seo";
import useSeoMdQuery from "./seoMd.graphql";
import useSiteMetaQuery from "../graphql/siteMeta.graphql";

export default props => {
   const seoMeta = useSiteMetaQuery();
   const pagesMetaQuery = useSeoMdQuery();

   return <SEO siteMeta={seoMeta} pagesMeta={pagesMetaQuery}></SEO>;
};
