import React, { useState, useContext } from "react";
import LinkMatix from "../link";
import useHeaderMdQuery from "./header.graphql";
import style from "./header.module.scss";
import c from "classnames";
import { ThemeContext } from "@matix/theme";
import { ScrollContext } from "@matix/theme";
import SubItems from "./subItems";
import PhoneSvg from "../fonts/phone";

export default props => {
   const [open, setOpen] = useState(false);
   const themeCtx = useContext(ThemeContext);
   const scrollCtx = useContext(ScrollContext);
   const data = useHeaderMdQuery().node.frontmatter;
   let navItems = data.navItems;

   return (
      <nav
         className={c(
            style.navigation,
            scrollCtx.isTop ? style.isTop : "",
            scrollCtx.isScrollingDown && !scrollCtx.isTop
               ? style.scrollingDown
               : !scrollCtx.isTop
               ? style.scrollingUp
               : "", // user is currently scrolling down or up but not at the top of page
            style[themeCtx.theme],
            open ? "" : style.collapsed
         )}>
         <button
            onClick={() => setOpen(!open)}
            className={c(
               style.togglerButton,
               "hamburger hamburger--spring",
               open ? "is-active" : ""
            )}
            type="button"
            aria-label="Menu">
            <span className="hamburger-box">
               <span className="hamburger-inner"></span>
            </span>
         </button>

         <ul>
            {navItems.map(navItem =>
               navItem.linkType === "tel" ? (
                  <li className={style.headerLink} key="phone">
                     <a href={navItem.link} target="_blank" rel="noopener noreferrer">
                        <div
                           className={c(
                              "d-flex animateLink justify-content-center align-items-center link",
                              style.headerText
                           )}>
                           <div>{navItem.text}</div>
                           <div style={{ marginLeft: "0.25rem" }}>
                              <PhoneSvg />
                           </div>
                        </div>
                     </a>
                  </li>
               ) : !navItem.subitems ? (
                  <li className={style.headerLink} key={navItem.text}>
                     <LinkMatix
                        activeClassName={style.active}
                        className="animateLink"
                        to={navItem.link}
                        role="list">
                        <div className={c("link", style.headerText)}>{navItem.text}</div>
                     </LinkMatix>
                  </li>
               ) : (
                  <li className={style.headerLink} key={navItem.text}>
                     <SubItems navItem={navItem} />
                  </li>
               )
            )}
         </ul>
      </nav>
   );
};
