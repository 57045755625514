import React from "react";
import Image from "gatsby-image";
import { GetImages } from "./getImagesFluid";
import { isPreview } from "./utils";

export default props => {
   const { imageName, imageAlt, fadeIn, loading } = props;

   let ImageCallback = GetImages;
   if (!ImageCallback) {
      ImageCallback = () => {
         return null;
      };
   }

   const imageLoadingType = loading ? loading : "lazy";
   const imageFadeIn = fadeIn ? fadeIn : false;

   return (
      <div className={props.className}>
         {isPreview() === true ? (
            <img src={`/img/${imageName}`} alt={imageAlt}></img>
         ) : fadeIn ? (
            <Image
               fluid={GetImages(imageName)}
               alt={imageAlt}
               fadeIn={imageFadeIn}
               loading={imageLoadingType}
            />
         ) : (
            <Image
               fluid={GetImages(imageName)}
               alt={imageAlt}
               fadeIn={imageFadeIn}
               loading={imageLoadingType}
            />
         )}
      </div>
   );
};
