import React from "react";
import { baseContextComponents, matixSections, matixComponent, lazyComponent } from "./matixConfig";
import { MdPageRenderer } from "@matix/markdown";

export default props => {
   return (
      <MdPageRenderer
         {...props}
         baseContextComponents={baseContextComponents}
         matixSections={matixSections}
         matixComponent={matixComponent}
         lazyComponent={lazyComponent}
      />
   );
};