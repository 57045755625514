import LinkMatix from "../link";
import React from "react";
import { MdStringRenderer } from "@matix/markdown";

export default props => {
   const { title, description, link, btntext } = props;

   return (
      <div className="container-fluid">
         <div className="row align-items-center bg-hero py-5">
            <div className="col-xl-9 py-5 mx-auto ">
               <div className="row py-2">
                  <div className="col-xl-7 col-lg-11 text-center text-lg-left mx-auto text-light">
                     <div className="mt-5 mb-4">
                        <MdStringRenderer source={title} />
                     </div>
                     <div className="mb-4">
                        <MdStringRenderer source={description} />
                     </div>

                     <LinkMatix to={link}>
                        <div className="btn btn-lg btn-warning btnText">{btntext}</div>
                     </LinkMatix>
                  </div>
                  <div className="col-lg"></div>
               </div>
            </div>
         </div>
      </div>
   );
};
