import { useStaticQuery, graphql } from "gatsby";
import GetTranslated from "../graphql/getTranslated";

export default props => {
   const query = useStaticQuery(
      graphql`
         query {
            allMarkdownRemark(filter: { frontmatter: { component: { eq: "Footer" } } }) {
               edges {
                  node {
                     frontmatter {
                        component
                        locale
                        telephone
                        email
                        developedBy
                        imageLogo
                        imageLogoAlt
                        companyName
                     }
                  }
               }
            }
         }
      `
   );
   return GetTranslated({query});
};
