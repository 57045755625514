import { useStaticQuery, graphql } from "gatsby";
import GetTranslated from "../graphql/getTranslated";

export default props => {
   const query = useStaticQuery(
      graphql`
         query {
            allMarkdownRemark(filter: { frontmatter: { component: { eq: "Header" } } }) {
               edges {
                  node {
                     frontmatter {
                        component
                        locale
                        navItems {
                           text
                           linkType
                           link
                           subitems {
                              text
                              linkType
                              link
                           }
                        }
                     }
                  }
               }
            }
         }
      `
   );
   return GetTranslated({query});
};
