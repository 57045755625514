import { useStaticQuery, graphql } from "gatsby";
import GetTranslated from "../graphql/getTranslated";

export default props => {
   const query = useStaticQuery(
      graphql`
         query {
            allMarkdownRemark(filter: {}) {
               edges {
                  node {
                     frontmatter {
                        component
                        locale
                        pagePath
                        siteName
                        title
                        description
                        keywords
                     }
                  }
               }
            }
         }
      `
   );
   return GetTranslated({query});
};
