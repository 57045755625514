import LinkMatix from "../link";
import React from "react";
import style from "./hero.module.scss";
import c from "classnames";
import { MdStringRenderer } from "@matix/markdown";
import ImageMatix from "../image";

export default props => {
   const { image, imagealt, title, subtitle, description, link, btntext } = props;
   return (
      <div className="bg-hero  d-flex flex-column">
         <div className={c("d-flex flex-column text-center text-light px-5", style.heroStyle)}>
            <div className={c(style.heroSpacing, "mt-4")}>
               <MdStringRenderer source={title} />
            </div>
            <div className={c(style.heroSpacing, "my-2")}>
               <MdStringRenderer source={subtitle} />
            </div>
            <LinkMatix to={link}>
               <span className="btn btn-lg btn-warning btnText my-4">
                  <MdStringRenderer source={btntext} />
               </span>
            </LinkMatix>

            <div className={style.heroSpacing}>
               <MdStringRenderer source={description} />
            </div>
         </div>

         <div className={style.imageContainer}>
            <ImageMatix
               className={style.image}
               imageName={image}
               imageAlt={imagealt}
               fadeIn={false}
               loading="eager"
            />
         </div>
      </div>
   );
};
