import React, { useContext } from "react";
import useFooterMdQuery from "./footer.graphql";
import c from "classnames";
import style from "./footer.module.scss";
import PhoneSvg from "../fonts/phoneLight";
import Envelope from "../fonts/envelope";
import { isMobile } from "../utils";
import { ThemeContext } from "@matix/theme";

export default props => {
   const data = useFooterMdQuery().node.frontmatter;
   const themeCtx = useContext(ThemeContext);

   return (
      <div
         className={c(
            themeCtx.theme !== "blue" ? "bg-footer" : style.footerColor,
            "fs-1",
            style.footerStyle
         )}>
         <div className="container">
            <div
               className={c(
                  "d-flex justify-content-around text-light text-center",
                  isMobile() ? "flex-column" : ""
               )}>
               <a
                  className="text-light p-3"
                  href={`tel:" + ${data.telephone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}>
                  <PhoneSvg />
                  <span style={{ marginLeft: "0.25rem" }}>{data.telephone}</span>
               </a>
               <a
                  className="text-light p-3"
                  href={`mailto:${data.email}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Envelope />
                  <span style={{ marginLeft: "0.25rem" }}>{data.email}</span>
               </a>
               <div className="p-3">{`  ${new Date().getFullYear()} - ${data.companyName}`}</div>
               <div className="p-3">{data.developedBy}</div>
            </div>
         </div>
      </div>
   );
};
