import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { MdStringRenderer } from "@matix/markdown";

export default props => {
   let matixTo = props.to;

   function isString(value) {
      return typeof value === "string" || value instanceof String;
   }

   if (matixTo && !isString(matixTo)) {
      matixTo = props.to.props.source;
   }

   const singleMatch = /\[([^[]+)\]\((.*)\)/;
   const match = singleMatch.exec(matixTo);
   const isMdLink = match ? true : false;
   let mdText = "";
   if (isMdLink) {
      matixTo = match[2];
      mdText = match[1];
   }
   if (!isMdLink) {
      return (
         <>
            {matixTo && matixTo.startsWith("http") ? (
               <a
                  className={props.className}
                  href={matixTo}
                  target="_blank"
                  rel="noopener noreferrer">
                  {props.text ? props.text : props.children}
               </a>
            ) : matixTo ? (
               <GatsbyLink
                  activeClassName={props.activeClassName}
                  className={props.className}
                  to={matixTo}>
                  <span className={props.className}>
                     {props.text ? props.text : props.children}
                  </span>
               </GatsbyLink>
            ) : (
               props.children
            )}
         </>
      );
   } else {
      return (
         <>
            {matixTo && matixTo.startsWith("http") ? (
               <MdStringRenderer source={props.to} />
            ) : (
               <GatsbyLink to={matixTo}>
                  <MdStringRenderer source={mdText} />
               </GatsbyLink>
            )}
         </>
      );
   }
};
