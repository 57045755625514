export const GetAshFromReactElement = comp => {
   let childs = [];
   if (comp.props && comp.props.children) {
      comp.props.children.forEach(child => {
         if (typeof child === "string") {
            childs = childs.concat(GetTextComponents(child));
         } else {
            childs = childs.concat(GetAshFromReactElement(child));
         }
      });
   }
   const className = comp.props ? (comp.props.className ? comp.props.className : "") : "";
   const style = comp.props ? (comp.props.style ? comp.props.style : "") : "";
   let result = GetElement(comp.type, childs);

   if (className) {
      result.properties.className = className;
   }
   if (style) {
      result.properties.style = style;
   }

   return result;
};

export const FilterUndefinedChildren = group => {
   if (group && group.children) {
      group.children.forEach(child => {
         if (child && typeof child !== "string" && child.children) {
            child.children = FilterUndefinedChildren(child);
         }
      });

      const result = group.children.filter(subchild => {
         if (!subchild) {
            return false;
         }
         if (subchild.type === "text" && subchild.value && subchild.value.trim() === "") {
            return false;
         }
         return true;
      });
      return result;
   }
};

export const GetTextComponents = text => {
   const comps = text.split("\n");
   return comps.map(item => {
      return GetText(item);
   });
};

const GetText = txt => {
   return {
      type: "text",
      value: txt
   };
};

export const GetElement = (tagName, children, node) => {
   let attributes = {};
   if (node && node.attributes) {
      for (let i = 0; i < node.attributes.length; i++) {
         let propKey = node.attributes[i].name;
         if (propKey === "class") {
            propKey = "className";
         }
         attributes[propKey] = node.attributes[i].value;
      }
   }
   return {
      type: "element",
      tagName: tagName,
      properties: attributes,
      children: children
   };
};

export const GetProperties = source => {
   let attributes = {};
   if (source && source.props) {
      Object.keys(source.props).forEach(propKey => {
         if (propKey !== "children") {
            attributes[propKey] = source.props[propKey];
         }
      });
   }

   return attributes;
};

export const GetComponentUsed = ({ lazyComponent, matixComponent, matixSections, groups }) => {
   const findModule = moduleName => {
      if (lazyComponent.hasOwnProperty(moduleName)) {
         return lazyComponent[moduleName];
      }
      if (matixSections && matixSections.hasOwnProperty(moduleName)) {
         return matixSections[moduleName];
      }
      if (matixComponent.hasOwnProperty(moduleName)) {
         return matixComponent[moduleName];
      }
      return null;
   };

   const parseComponentTree = parent => {
      if (Array.isArray(parent)) {
         parent.forEach(child => {
            parseComponentTree(child);
         });
      } else {
         if (parent.tagName) {
            const compUsed = findModule(parent.tagName);
            if (compUsed !== null) {
               usedComp[parent.tagName] = compUsed;
            }
            if (Array.isArray(parent.children)) {
               parent.children.forEach(child => {
                  parseComponentTree(child);
               });
            }
         } else if (parent.type === "text") {
            const matixTextcomp = findModule("matix-text");
            usedComp["matix-text"] = matixTextcomp;
         }
      }
   };

   const usedComp = {};
   parseComponentTree(groups);
   return usedComp;
};
