export const isMobile = () => {
   if (isSSR()) return false;

   return window.innerWidth <= 1000;
};

export const isSSR = () => {
   return typeof window === "undefined";
};

export const isPreview = () => {
   if (isSSR()) return false;

   if (window.location && window.location.origin) {
      return window.location.pathname === "/admin/";
   } else {
      return false;
   }
};
