import useSiteMetaQuery from "./siteMeta.graphql";
import { isSSR } from "../utils";

export default props => {
   const { query } = props;
   const website = useSiteMetaQuery();
   const translatedLocal = website.translatedLocal;

   let lang = website.defaultLocale;
   if (!isSSR()) {
      lang = window.location.pathname.includes(translatedLocal)
         ? translatedLocal
         : website.defaultLocale;
   }

   const result = query.allMarkdownRemark.edges.filter(edge => {
      return (
         edge.node.frontmatter.locale === lang &&
         (query.componentName ? edge.node.frontmatter.component === query.componentName : 1)
      );
   });

   if (result && result.length === 1) {
      return result[0];
   } else {
      return result;
   }
};
