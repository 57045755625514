import React, { useState } from "react";
import style from "./header.module.scss";
import LinkMatix from "../link";
import { Collapse } from "reactstrap";
import c from "classnames";
import AngleUp from "../fonts/angleup";
import AngleDown from "../fonts/angledown";
export default props => {
   const { navItem } = props;

   const [isOpen, setIsOpen] = useState(false);

   return (
      <div
         className={c("d-flex flex-column", style.subItemsBtn)}
         onClick={() => setIsOpen(!isOpen)}
         onKeyDown={() => setIsOpen(!isOpen)}
         role="button"
         tabIndex={0}>
         <div className={c(style.subItemsLink)}>
            <div
               className={c(
                  "d-flex animateLink justify-content-center align-items-center link",
                  style.headerText
               )}>
               <div>{navItem.text}</div>
               {isOpen ? (
                  <div style={{ marginTop: "0.2rem" }}>
                     <AngleUp />
                  </div>
               ) : (
                  <div style={{ marginTop: "0.2rem" }}>
                     <AngleDown />
                  </div>
               )}
            </div>
         </div>
         <div className={style.subItemCollapse}>
            <Collapse className={style.subItems} isOpen={isOpen}>
               {navItem.subitems.map(subItem => (
                  <LinkMatix
                     key={subItem.text}
                     activeClassName={style.active}
                     className={c("animateLink", style.subItemSection)}
                     to={subItem.link}
                     role="list">
                     <div className={c("link", style.headerText)}>{subItem.text}</div>
                  </LinkMatix>
               ))}
            </Collapse>
         </div>
      </div>
   );
};
