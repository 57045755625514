import ImageMatix from "../image";
import React from "react";
import style from "./hero.module.scss";
import { isMobile, isSSR } from "../utils";

export default props => {
   const { imagename, imagealt } = props;

   let windowHeight = "100vh";
   if (!isSSR()) {
      windowHeight = window.innerHeight;
      if (!isMobile) {
         windowHeight = windowHeight - 200;
      }
   }

   return (
      <section>
         <div className={style.backgroundFullscreen} style={{ height: windowHeight }}>
            <ImageMatix
               {...{
                  className: style.overlayBg,
                  withOverlay: true,
                  imageName: imagename,
                  isFullscreen: true,
                  height: windowHeight,
                  imageAlt: imagealt,
                  overlayOpacity: 0.85,
                  loading: "eager"
               }}></ImageMatix>
         </div>
      </section>
   );
};
