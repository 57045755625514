import React from "react";
import Helmet from "react-helmet";
import Facebook from "./facebook";

export const SEO = ({ siteMeta, pagesMeta }) => {
   let siteSeoMetadata = siteMeta;

   const getImage = imgName => {
      if (!pagesMeta.images) {
         return "";
      }
      const foundImg = pagesMeta.images.edges.find(edge => edge.node.name === imgName);
      if (!foundImg) {
         return "";
      }
      return foundImg.node.childImageSharp;
   };

   let allpages = [];

   pagesMeta.forEach(page => {
      if (page.node.frontmatter.pagePath) {
         allpages.push(page.node.frontmatter);
      }
   });

   const isSSR = typeof window === "undefined";
   const currentPagePath = !isSSR && window.location ? window.location.pathname : "/";

   const currentSiteUrl = siteSeoMetadata.siteUrl + currentPagePath;

   const imgLogo = getImage(siteSeoMetadata.logo);

   const webSiteLogoUrl = `${siteSeoMetadata.siteUrl}${imgLogo !== "" ? imgLogo.fixed.src : ""}`;
   const seoShareImage = getImage(siteSeoMetadata.seoImageStore);
   const seoShareImageSrc = `${siteSeoMetadata.siteUrl}${
      seoShareImage !== "" ? seoShareImage.fluid.src : ""
   }`;

   const getSeoValue = (key, pagePath) => {
      let mdPage = pagesMeta.find(page => {
         return page.node.frontmatter.pagePath === pagePath;
      });
      if (!mdPage || !mdPage.node.frontmatter[key]) {
         return "";
      }
      return mdPage.node.frontmatter[key];
   };

   const currentLang = getSeoValue("locale", currentPagePath);

   let seo = {
      title: getSeoValue("title", currentPagePath),
      description: getSeoValue("description", currentPagePath),
      keywords: getSeoValue("keywords", currentPagePath),
      image: `${seoShareImageSrc}`
   };

   // level0/Level1/level2...
   function addLevel(pageUrl, pos) {
      let itemId = siteSeoMetadata.siteUrl + pageUrl;
      if (itemId.endsWith("/")) {
         itemId = itemId.slice(0, itemId.length - 1);
      }
      itemListElement.push({
         "@type": "ListItem",
         position: pos,
         item: {
            "@id": itemId,
            name: getSeoValue("siteName", pageUrl),
            description: getSeoValue("description", pageUrl)
         }
      });
   }

   let itemListElement = [];
   allpages.forEach((currentPage, index) => {
      if (currentPage.pagePath) {
         addLevel(currentPage.pagePath, index === 0 ? 0 : 1);
      }
   });

   const nbItem = itemListElement.length;

   const breadcrumb = {
      "@type": "BreadcrumbList",
      name: siteSeoMetadata.companyName,
      numberOfItems: nbItem,
      itemListElement
   };

   const corporateContact = {
      "@type": "Organization",
      url: siteSeoMetadata.siteUrl,
      address: siteSeoMetadata.postalAdress,
      email: siteSeoMetadata.email,
      logo: webSiteLogoUrl,
      legalName: siteSeoMetadata.companyName,
      contactPoint: [
         {
            "@type": "ContactPoint",
            telephone: siteSeoMetadata.telephone,
            email: siteSeoMetadata.email,
            contactType: "customer service",
            areaServed: "CA",
            availableLanguage: ["French", "English"] // todo: populate from list of locales
         }
      ]
   };

   const openingHoursSpecification = siteSeoMetadata.openingHoursSpecification.map(info => {
      const hoursSpec = {
         "@type": "OpeningHoursSpecification",
         dayOfWeek: info.dayOfWeek,
         opens: info.opens,
         closes: info.closes
      };
      return hoursSpec;
   });

   const localBusinessInfo = {
      "@type": "Store",
      "@id": siteSeoMetadata.siteUrl,
      url: currentSiteUrl,
      name: siteSeoMetadata.companyName,
      telephone: siteSeoMetadata.telephone,
      address: siteSeoMetadata.postalAdress,
      image: webSiteLogoUrl,
      openingHoursSpecification
   };

   const groupRichContentsGraph = {
      "@context": "https://schema.org",
      "@graph": [breadcrumb, corporateContact, localBusinessInfo]
   };

   const fbLocal = currentLang + "_CA";
   const image32 = getImage("favicon-32x32");
   const flavicon32x = image32 !== "" ? siteSeoMetadata.siteUrl + image32.fluid.src : "";
   const image16 = getImage("favicon-16x16");
   const flavicon16x = image16 !== "" ? siteSeoMetadata.siteUrl + image16.fluid.src : "";
   const imageX = getImage("favicon");
   const flavicon = imageX !== "" ? siteSeoMetadata.siteUrl + imageX.fluid.src : "";

   return (
      <>
         <Helmet title={seo.title}>
            <html lang={currentLang} />
            <meta name="robots" content="Index, Follow" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="title" content={seo.title} />
            <meta name="keywords" content={seo.keywords} />
            <link rel="icon" type="image/png" sizes="144x144" href={flavicon} />
            <link rel="icon" type="image/png" sizes="32x32" href={flavicon32x} />
            <link rel="icon" type="image/png" sizes="16x16" href={flavicon16x} />
            <script type="application/ld+json">{JSON.stringify(groupRichContentsGraph)}</script>
         </Helmet>
         {
            <Facebook
               desc={seo.description}
               image={seoShareImageSrc}
               imageWidth={seoShareImage.width}
               imageHeight={seoShareImage.height}
               title={seo.title}
               type={"website"}
               url={currentSiteUrl}
               locale={fbLocal}
               name={siteSeoMetadata.companyName}
            />
         }
      </>
   );
};
