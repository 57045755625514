import { useStaticQuery, graphql } from "gatsby";

export default props => {
   const query = useStaticQuery(
      graphql`
         query {
            allMarkdownRemark(filter: { frontmatter: { id: { eq: "seoMetaData" } } }) {
               edges {
                  node {
                     frontmatter {
                        id
                        component
                        type
                        companyName
                        telephone
                        defaultLocale
                        translatedLocal
                        postalAdress {
                           streetAddress
                           addressLocality
                           addressRegion
                           postalCode
                           addressCountry
                        }
                        email
                        openingHoursSpecification {
                           info {
                              dayOfWeek
                              opens
                              closes
                           }
                        }
                        siteUrl
                        logo
                        seoImageStore
                        author
                     }
                  }
               }
            }
         }
      `
   );
   return query.allMarkdownRemark.edges[0].node.frontmatter;
};
