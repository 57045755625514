import { isMobile } from "./utils";
import useGetImagesQuery from "./graphql/getImage.graphql";
import useHeroImageQuery from "./graphql/getHeroImage.graphql";

export const GetImages = props => {
   let images = useGetImagesQuery();
   let heroImg = useHeroImageQuery();

   if (!props) return null;

   const getImage = name => {
      if (!name) return null;
      let imageFound = null;

      if (isMobile()) {
         const mobiletag = `${name}_mobile`;
         imageFound = heroImg.mobile.edges.find(edge => {
            return edge.node.name === mobiletag || edge.node.relativePath.endsWith(mobiletag);
         });

         if (!imageFound) {
            imageFound = images.mobile.edges.find(edge => {
               return edge.node.name === mobiletag || edge.node.relativePath.endsWith(mobiletag);
            });
         }
         if (!imageFound) {
            imageFound = images.mobile.edges.find(edge => {
               return edge.node.name === name || edge.node.relativePath.endsWith(name);
            });
         }
      } else {
         imageFound = images.desktop.edges.find(edge => {
            return edge.node.name === name || edge.node.relativePath.endsWith(name);
         });
      }

      if (!imageFound) {
         imageFound = images.mobile.edges.find(edge => {
            return (
               edge.node.name === "DoSomethingGreat" ||
               edge.node.relativePath.endsWith("DoSomethingGreat")
            );
         });
      }

      return imageFound.node.childImageSharp.fluid;
   };

   function isString(value) {
      return typeof value === "string" || value instanceof String;
   }

   if (isString(props)) {
      let image = getImage(props);
      return image;
   } else {
      let results = [];
      props.forEach(name => {
         let image = getImage(name);
         results.push({ image });
      });
      return results;
   }
};
