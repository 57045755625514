import { useStaticQuery, graphql } from "gatsby";

export default props => {
   const queries = useStaticQuery(
      graphql`
         {
            mobile: allFile(
               filter: {
                  name: { eq: "DoSomethingGreat_mobile" }
                  extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
               }
            ) {
               edges {
                  node {
                     relativePath
                     name
                     childImageSharp {
                        fluid(maxWidth: 360, quality: 80) {
                           ...GatsbyImageSharpFluid_withWebp_noBase64
                           presentationWidth
                        }
                     }
                  }
               }
            }
         }
      `
   );

   return queries;
};
